import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landingpage from './components/Landingpage';
import Header from './components/Header';
import WildForestHoney from './components/HoneyBuypage/WildForestHoney';
import AcaciaHoney from './components/HoneyBuypage/AcaciaHoney';
import MoringaHoney from './components/HoneyBuypage/MoringaHoney';
import Arabicacoffee from './components/CoffeeBuypage/ArabicaCoffee';
import RobustaCoffee from './components/CoffeeBuypage/RobustaCoffee';
import Mailsent from './components/Mailsent/Mailsent';
import ContactUs from './components/ContactUs';
import NotFound from './components/NotFound';
import OrderSummary from './components/OrderSummary';
import OrderForm from './components/OrderForm';
import Confimedorder from './components/Confimedorder';
import HoneyProducts from './components/HoneyProducts';
import CoffeeProducts from './components/CoffeeProducts';
import HoneyProducts2 from './components/HoneyProducts2';
import CoffeeProducts2 from './components/CoffeeProducts2';
import Termsandconditions from './components/Termsandconditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import ShippingPolicy from './components/ShippingPolicy';
import CancellationPolicy from './components/CancellationPolicy';
import QualityProduct from './components/QualityProduct';
import ProtectYourDetails from './components/ProtectYourDetails';
import FSSAILicense from './components/FSSAILicense';
import AboutUs from './components/Aboutus';
import Checkoutpage from './components/Checkoutpage';
import Admin from './components/AdminDashboard';
import Useraccount from './components/Useraccout';
import Userregister from './components/UserRegister/UserRegister'; // Corrected the path
import Userlogin from './components/UserLogin/UserLogin'; // Corrected the path
import './App.css';

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Landingpage />} />
        <Route path="/wildforesthoney" element={<WildForestHoney />} />
        <Route path="/AcaciaHoney" element={<AcaciaHoney />} />
        <Route path="/MoringaHoney" element={<MoringaHoney />} />
        <Route path="/Arabicacoffee" element={<Arabicacoffee />} />
        <Route path="/Robustacoffee" element={<RobustaCoffee />} />
        <Route path="/mailsent" element={<Mailsent />} />
        <Route path="/login2" element={<Useraccount />} /> 
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/notfound" element={<NotFound />} />
        <Route path="/OrderForm" element={<OrderForm />} />
        <Route path="/Confimedorder" element={<Confimedorder />} />
        <Route path="/HoneyProducts" element={<HoneyProducts />} />
        <Route path="/CoffeeProducts" element={<CoffeeProducts />} />
        <Route path="/HoneyProducts2" element={<HoneyProducts2 />} />
        <Route path="/CoffeeProducts2" element={<CoffeeProducts2 />} />
        <Route path="/ordersummary" element={<OrderSummary />} />
        <Route path="/termsandconditions" element={<Termsandconditions />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/shippingpolicy" element={<ShippingPolicy />} />
        <Route path="/cancellationpolicy" element={<CancellationPolicy />} />
        <Route path="/qualityproduct" element={<QualityProduct />} />
        <Route path="/protectyourdetails" element={<ProtectYourDetails />} />
        <Route path="/fssailicense" element={<FSSAILicense />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/checkoutpage" element={<Checkoutpage />} />
        <Route path="/MoringaHoney/checkoutpage" element={<Checkoutpage />} />
        <Route path="/AcaciaHoney/checkoutpage" element={<Checkoutpage />} />
        <Route path="/Arabicacoffee/checkoutpage" element={<Checkoutpage />} />
        <Route path="/Robustacoffee/checkoutpage" element={<Checkoutpage />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/register" element={<Userregister />} />
        <Route path="/login" element={<Userlogin />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
