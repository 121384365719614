import React from 'react';
import './Mailsent.css'

const mailsent = () => {
  return (
    <div className='mailsent-container'>        
      <div>
      <img
        src="https://res.cloudinary.com/drevfgyks/image/upload/v1713790540/B%20organics/3343839_qmyp6m.jpg"
        alt="Mail received"
        className='desktop-image'
        style={{ width: '300px', margin: '20px auto' }}
      />
      </div>
      <div className='righttick'>
        <p className='Receivedmail'>We will reply soon.</p>
      <img
        src="https://res.cloudinary.com/drevfgyks/image/upload/v1713367085/B%20organics/R_1_tj52bu.png"
        alt="Organic logo"
        className='img-tick'
      />      
      </div>     
    </div>
  );
};

export default mailsent;
