import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCartPlus,faUser} from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
// import CartList from "./components/CartList"; // Import CartList component
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './index.css';

const Header = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const [isCartOpen, setIsCartOpen] = useState(false); // State to manage cart visibility


    const handleNavbarToggle = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    const handleCartToggle = () => {
        setIsCartOpen(!isCartOpen);
    };

    const handleMenuItemClick = () => {
        setIsNavbarOpen(false); // Close the navbar when a menu item is clicked
    };

    useEffect(() => {
        const cursol = document.querySelector(".cursol");
        const cursol2 = document.querySelector(".cursol2");
    
        const handleMouseMove = (e) => {
            cursol.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
            cursol2.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
        };
    
        const handleMouseEnter = () => {
            cursol.classList.add("hover-effect");
        };
    
        const handleMouseLeave = () => {
            cursol.classList.remove("hover-effect");
        };
    
        document.addEventListener("mousemove", handleMouseMove);
        document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
            element.addEventListener("mouseenter", handleMouseEnter);
            element.addEventListener("mouseleave", handleMouseLeave);
        });
    
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
                element.removeEventListener("mouseenter", handleMouseEnter);
                element.removeEventListener("mouseleave", handleMouseLeave);
            });
        };
    }, []);
        

    return (
        <>
            <div className='container-fluid'>
            <nav className="navbar container-nav navbar-expand-lg navbar-light fixed-top">                 
                <div className='heading-container'>
                    <Link to='/' className='heading-container'>
                        <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1712129337/1_ozrowa.png"
                        className="Borgani-logo"
                        alt='image9'
                        />
                       <h1 className='borgnanics-heading'>B Organics</h1>                 
                    </Link>                            
                </div>

                <div className='topleft-nav-container'>
                    <button className="navbar-toggler" type="button" onClick={handleNavbarToggle}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>

                <div className='dropdown-toggle-container'>
                    <div className={`collapse navbar-collapse ${isNavbarOpen ? 'show' : ''}`}>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to='/' className="nav-link" onClick={handleMenuItemClick}>Home</Link>
                            </li>
                            <li className="nav-item dropdown Shop-Categories">
                                <button className="btn dropdown-toggle Shop-Categories nav-link" data-bs-toggle="dropdown" aria-expanded="false">
                                    Shop Categories
                                </button>
                                <ul className="dropdown-menu dropdown-menu-light">
                                    <Link to="/HoneyProducts2" className="dropdown-item" onClick={handleMenuItemClick}>Honey Products</Link>
                                    <Link to="/CoffeeProducts2" className="dropdown-item" onClick={handleMenuItemClick}>Coffee Products</Link>
                                </ul>
                            </li>
                        </ul>
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link to='/contactus' className="nav-link" onClick={handleMenuItemClick}>Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link to='/aboutus' className="nav-link" onClick={handleMenuItemClick}>About Us</Link>
                            </li>
                            {/* Add your other navigation items here */}
                        </ul>
                    </div>
                </div>                    
                    <div className='userlogin-navbar'>                                                 
                            <Link to="/login" className='linkforAddcart' onClick={handleCartToggle}>
                                <FontAwesomeIcon className="cart-icon" icon={faUser} />
                            </Link>
                            <Link to="/Addcart" className='linkforAddcart' onClick={handleCartToggle}>
                                <FontAwesomeIcon className="cart-icon" icon={faCartPlus} />
                            </Link>
                            {/* Display CartList component based on isCartOpen state */}
                            {/* {isCartOpen && <CartList />} */}
                    </div> 
                    <div className="cursol"></div>
                    <div className="cursol2"></div>               
                </nav>
            </div>
        </>
    );
};

export default Header;
