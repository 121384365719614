// import Footer from "../Footer";
// import { useNavigate } from "react-router-dom";
// import * as React from "react";
// import {
//   Flex,
//   Box,
//   FormControl,
//   FormLabel,
//   Input,
//   Stack,
//   Button,
//   Heading,
//   Text,
//   Textarea,
// } from "@chakra-ui/react";
// import { useState } from "react";
// import "./Contact.css";

// export default function MyForm() {
//   const [email, setEmail] = useState("");
//   const [subject, setSubject] = useState("");
//   const [message, setMessage] = useState("");
//   const [error, setError] = useState("");
//   const history = useNavigate()

//   const baseUrl = "https://borganics-backend-code.onrender.com";

//   const sendEmail = async () => {
//     let dataSend = {
//       email: email,
//       subject: subject,
//       message: message,
//     };

//     try {
//       const res = await fetch(`${baseUrl}/send/email`, {
//         method: "POST",
//         body: JSON.stringify(dataSend),
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//       });

//       if (res.status > 199 && res.status < 300) {
//         history('/mailsent');
//         // alert("Send Successfully !");
//       } else {
//         throw new Error("Failed to send email.");
//       }
//     } catch (err) {
//       setError(err.message);
//     }
//   };

//   return (
//     <>
//     <Flex
//       minH={"100vh"}
//       align={"center"}
//       justify={"center"}
//       bg={"gray.50"}
//     >
//       <Stack className="contaictus-container" spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
//         <Stack align={"center"}>
//           <Heading fontSize={"4xl"}>Send email to the account</Heading>
//         </Stack>
//         <Box
//           rounded={"lg"}
//           bg={"white"}
//           boxShadow={"lg"}
//           p={8}
//           className='customBox'
//         >
//           <Stack spacing={4}>
//             <FormControl id="email">
//               <FormLabel>Email address</FormLabel>
//               <Input
//                 type="email"
//                 placeholder="Receiver's Email Address"
//                 onChange={(e) => setEmail(e.target.value)}
//                 className="customInput"
//               />
//             </FormControl>
//             <FormControl id="subject">
//               <FormLabel>Subject</FormLabel>
//               <Input
//                 onChange={(e) => setSubject(e.target.value)}
//                 type="text"
//                 placeholder="Enter the subject here..."
//                 className="customInput"
//               />
//             </FormControl>
//             <FormControl id="text">
//               <FormLabel>Message</FormLabel>
//               <Textarea
//                 onChange={(e) => setMessage(e.target.value)}
//                 placeholder="Enter your message here..."
//                 className="customTextarea"
//               />
//             </FormControl>
//             <Button
//               bg={"blue.400"}
//               color={"white"}
//               _hover={{
//                 bg: "blue.500",
//               }}
//               onClick={() => sendEmail()}
//               className="customButton"
//             >
//             Send Email
//             </Button>
//             {error && (
//               <Text color="red.500" fontSize="sm">
//                 {error}
//               </Text>
//             )}
//           </Stack>          
//         </Box>       
//       </Stack>         
//     </Flex>
//     <Footer/>
//     </>
//   );
// }

import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  Textarea,
  Spinner,
} from "@chakra-ui/react";
import { useState } from "react";
import "./Contact.css";

export default function MyForm() {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [loading, setLoading] = useState(false); // State for loading spinner
  const history = useNavigate();

  const baseUrl = "https://borganics-backend-code.onrender.com";

  const validateForm = () => {
    let valid = true;
    if (!email) {
      setEmailError("Email is required");
      valid = false;
    } else {
      setEmailError("");
    }
    if (!subject) {
      setSubjectError("Subject is required");
      valid = false;
    } else {
      setSubjectError("");
    }
    if (!message) {
      setMessageError("Message is required");
      valid = false;
    } else {
      setMessageError("");
    }
    return valid;
  };

  const sendEmail = async () => {
    if (!validateForm()) return;

    setLoading(true); // Show spinner
    let dataSend = {
      email: email,
      subject: subject,
      message: message,
    };

    try {
      const res = await fetch(`${baseUrl}/send/email`, {
        method: "POST",
        body: JSON.stringify(dataSend),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (res.status > 199 && res.status < 300) {
        setLoading(false); // Hide spinner
        history('/mailsent');
        // alert("Send Successfully !");
      } else {
        throw new Error("Failed to send email.");
      }
    } catch (err) {
      setError(err.message);
      setLoading(false); // Hide spinner
    }
  };

  return (
    <>
      <Flex
        minH={"100vh"}
        align={"center"}
        justify={"center"}
        bg={"gray.50"}
      >
        <Stack className="contaictus-container" spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>Send email to the account</Heading>
          </Stack>
          <Box
            rounded={"lg"}
            bg={"white"}
            boxShadow={"lg"}
            p={8}
            className='customBox'
          >
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Input
                  type="email"
                  placeholder="Receiver's Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  className="customInput"
                />
                {emailError && <Text color="red.500" className="Worning-mes" fontSize="sm">{emailError}</Text>}
              </FormControl>
              <FormControl id="subject">
                <FormLabel>Subject</FormLabel>
                <Input
                  onChange={(e) => setSubject(e.target.value)}
                  type="text"
                  placeholder="Enter the subject here..."
                  className="customInput"
                />
                {subjectError && <Text color="red.500" className="Worning-mes" fontSize="sm">{subjectError}</Text>}
              </FormControl>
              <FormControl id="text">
                <FormLabel>Message</FormLabel>
                <Textarea
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter your message here..."
                  className="customTextarea"
                />
                {messageError && <Text color="red.500" className="Worning-mes" fontSize="sm">{messageError}</Text>}
              </FormControl>
              <Button
                bg={"blue.400"}
                color={"white"}
                _hover={{
                  bg: "blue.500",
                }}
                onClick={sendEmail}
                className="customButton button-Contactus"
                disabled={loading} // Disable button while loading
                alt="Submit"
              >
                {loading ? <Spinner className="spinnerForEmail" /> : (
                  <>
                    <i>s</i>
                    <i>e</i>
                    <i>n</i>
                    <i>d</i>
                    <i>&nbsp;</i>
                    <i>n</i>
                    <i>o</i>
                    <i>w</i>
                  </>
                )}
              </Button>

              {error && (
                <Text color="red.500" fontSize="sm">
                  {error}
                </Text>
              )}
            </Stack>
          </Box>
        </Stack>
      </Flex>
      <Footer />
    </>
  );
}
