// import React, { useEffect, useState } from 'react';
// import Footer from '../../Footer';
// import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate
// import RecommendProduct from '../../RecommendProduct'
// import { motion } from 'framer-motion';
// import Coffee_Arabico50 from './Coffeeimages/Coffee_Arabico-50g.jpg';
// import Coffee_Arabico100 from './Coffeeimages/Coffee_Arabico-50gm.jpg';
// import Coffee_Arabico250 from './Coffeeimages/Coffee_Arabico-250g.jpg';
// import './index.css'

// const HoneyBuypage = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
// }, []);

//   const navigate = useNavigate(); // Initialize useNavigate
//   const [selectedSize, setSelectedSize] = useState("250g");
//   const [selectedProduct, setSelectedProduct] = useState({
//     name: 'Arabic AAA Honey',
//     size: '250g',
//     price: 'Rs 690.99',
//   });


//   const handleSizeClick = (size) => {
//     setSelectedSize(size);
//   };
  
//   useEffect(() => {
//     if (selectedSize) {
//       setSelectedProduct({
//         name: 'Arabic AAA Honey',
//         size: selectedSize,
//         price: getPrice(selectedSize),
//       });
//     }
//   }, [selectedSize]);

//   const getPrice = (size) => {
//     switch (size) {
//       case '50g':
//         return 'Rs 165.99';
//       case '100g':
//         return 'Rs 290.99';
//       case '250g':
//         return 'Rs 690.99';
//       default:
//         return '';
//     }
//   };

//   const handleBuyNow = () => {
//     const selectedProduct = {
//       name: 'Arabic AAA',
//       size: selectedSize,
//       price: getPrice(selectedSize),
//     };
//     navigate('/Arabicacoffee/checkoutpage', { state: { selectedProduct } }); // Navigate to checkout page with state
//   };

//   return (
//     <>
//     <motion.div
//             initial={{ opacity: 5, y: -20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 0.9 }}
//         >
//       <div className='Honey-product-container'>
//         <div className='container mt-5'>
//           <div className='row'>
//             <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex  justify-content-center">
//               <div className="product-bottle">
//                 <div className="product-card-container">
//                   <div>
//                     {/* Product Image and Preview Images */}
//                     {selectedSize === "50g" && (
//                       <img src={Coffee_Arabico50}
//                       className='Honey-product' alt="Honey product" />

//                     )}
//                     {selectedSize === "100g" && (
//                       <img src={Coffee_Arabico100} className='Honey-product' alt="Honey product" />
//                     )}
//                     {selectedSize === "250g" && (
//                       <img src={Coffee_Arabico250} className='Honey-product' alt="Honey product" />
//                     )}
//                   </div>
//                 </div>
//                 {/* Other product preview images */}
//               </div>
//             </div>
//             <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5 justify-content-center'>
//               <h1 className="name-for-product mt-5">Arabic AAA</h1>
//               <div>
//                     {/* Product Image and Preview Images */}
//                     {selectedSize === "50g" && (
//                       <p>size : 50g<br/>Price : Rs 165.99</p>             
//                     )}
//                     {selectedSize === "100g" && (
//                       <p>size : 100g<br/>Price : Rs 290.99</p> 
//                     )}
//                     {selectedSize === "250g" && (
//                       <p>size : 250g<br/>Price : Rs 690.99</p> 
//                     )}
//                   </div>
//               {/* Product description */}
//               <div className='prodcut-size-card'>
//                 <p>Size</p>
//                 <p className='prodcut-size-Arabica' onClick={() => handleSizeClick("50g")}>50 gm</p>
//                 <p className='prodcut-size-Arabica' onClick={() => handleSizeClick("100g")}>100 gm</p>
//                 <p className='prodcut-size-Arabica' onClick={() => handleSizeClick("250g")}>250 gm</p>
//               </div>
//               <div className='button_container'>
              
//                 <button className='button_BuyNow' onClick={handleBuyNow}>Buy Now</button>
//                 {/* <button className='button_AddCart'>Add Cart</button> */}
//               </div>
//               {/* Other product details */}
//             </div>
//           </div>
//         </div>
//         <hr className='hrClass'/>
//         <h1 className="menu-section-heading mb-2 mt-5">Recommendation</h1>
//         <div className="explore-menu-section2" id="exploreMenuSection">
//             <div className="cardcenter">   
//               {/* Pass selectedSize as prop to RecommendProduct */}
//               <RecommendProduct
//                 imageSrc={Coffee_Arabico50}
//                 name="Arabic AAA-50g"
//                 price="Rs 165.99"
//                 size="50g"
//               />
//               <RecommendProduct
//                 imageSrc={Coffee_Arabico100}
//                 name="Arabic AAA-100g"
//                 price="Rs 290.99"
//                 size="100g"
//               />
//               <RecommendProduct
//                 imageSrc={Coffee_Arabico250}
//                 name="Arabic AAA-250g"
//                 price="Rs 690.99"
//                 size="250g"
//               />
//             </div>
//           </div>
//         <Footer />
//       </div>
//       </motion.div>
//     </>
//   );
// };

// export default HoneyBuypage;


import React, { useEffect, useState } from 'react';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import RecommendProduct from '../../RecommendProduct'
import { motion } from 'framer-motion';
import './index.css'

const Coffee_Arabico50 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/1_fw1aob.jpg"
const Coffee_Arabico100 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/1_fw1aob.jpg"
const Coffee_Arabico250 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/1_fw1aob.jpg"

const HoneyBuypage = () => {
  useEffect(() => {
    const cursol = document.querySelector(".cursol");
    const cursol2 = document.querySelector(".cursol2");

    const handleMouseMove = (e) => {
        cursol.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
        cursol2.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
    };

    const handleMouseEnter = () => {
        cursol.classList.add("hover-effect");
    };

    const handleMouseLeave = () => {
        cursol.classList.remove("hover-effect");
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
        element.addEventListener("mouseenter", handleMouseEnter);
        element.addEventListener("mouseleave", handleMouseLeave);
    });

    return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
            element.removeEventListener("mouseenter", handleMouseEnter);
            element.removeEventListener("mouseleave", handleMouseLeave);
        });
    };
}, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate(); // Initialize useNavigate
  const [selectedSize, setSelectedSize] = useState('250g');

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleBuyNow = () => {
    const selectedProduct = {
      name: 'Arabic AAA',
      size: selectedSize,
      price: getPrice(selectedSize),
    };
    navigate('/Arabicacoffee/checkoutpage', { state: { selectedProduct } }); // Navigate to checkout page with state
  };

  const getPrice = (size) => {
    switch (size) {
      case '50g':
        return 'Rs 165.99';
      case '100g':
        return 'Rs 290.99';
      case '250g':
        return 'Rs 690.99';
      default:
        return '';
    }
  };

  const getImageUrl = (size) => {
    switch (size) {
      case '50g':
        return Coffee_Arabico50;
      case '100g':
        return Coffee_Arabico100;
      case '250g':
        return Coffee_Arabico250;
      default:
        return '';
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 5, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.9 }}
      >
        <div className='Coffee-product-container-Ara'>
          <div className='container mt-5'>
            <div className='row'>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex  justify-content-center">
                <div className="product-bottle">
                  <div className="product-card-container">
                    <img
                      src={getImageUrl(selectedSize)}
                      className='Honey-product' alt="Honey-product"
                    />
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5 justify-content-center'>
                <h1 className="name-for-product mt-5">Arabic AAA</h1>
                <div>
                  {selectedSize && (
                    <p className='select-container3'>size : {selectedSize}<br/>Price : {getPrice(selectedSize)}</p>             
                  )}
                </div>
                <div className='prodcut-size-card'>
                  <label htmlFor="size">Size:</label>
                  <select id="size" className='select-container select-container2' value={selectedSize} onChange={handleSizeChange}>
                    <option value="50g">50 gm</option>
                    <option value="100g">100 gm</option>
                    <option value="250g">250 gm</option>
                  </select>
                </div>
                <div className='button_container'>
                  <button className='button_BuyNow' onClick={handleBuyNow}>Buy Now</button>
                </div>
              </div>
            </div>
          </div>
          <hr className='hrClass'/>
          <h1 className="menu-section-heading mb-2 mt-5">Recommendation</h1>
          <div className="explore-menu-section2" id="exploreMenuSection">
            <div className="cardcenter">   
              <RecommendProduct
                imageSrc={Coffee_Arabico50}
                name="Arabic AAA-50g"
                price="Rs 165.99"
                size="50g"
              />
              <RecommendProduct
                imageSrc={Coffee_Arabico100}
                name="Arabic AAA-100g"
                price="Rs 290.99"
                size="100g"
              />
              <RecommendProduct
                imageSrc={Coffee_Arabico250}
                name="Arabic AAA-250g"
                price="Rs 690.99"
                size="250g"
              />
            </div>
          </div>
          <Footer />
        </div>
      </motion.div>
      <div className="cursol"></div>
      <div className="cursol2"></div>
    </>
  );
};

export default HoneyBuypage;



