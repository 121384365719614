import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import './index.css';

const HoneyProducts = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const honeyProducts = [
        {
            name: 'Wild Forest Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/3_hxrhgi.jpg',
            path: '/wildforesthoney',
            price: 'Rs 410.00',
            availableSizes: '50gm, 100gm, 250gm'
        },
        {
            name: 'Acacia Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/4_g803yw.jpg',
            path: '/acaciahoney',
            price: 'Rs 410.00',
            availableSizes: '50gm, 100gm, 250gm'
        },
        {
            name: 'Moringa Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/2_tbtdul.jpg',
            path: '/moringahoney',
            price: 'Rs 410.00',
            availableSizes: '50gm, 100gm, 250gm'
        }
    ];

    return (
        <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9 }}
            className="honey-products"
        >
            <div className='HoneyMain-Container'>
                <div className="Honey-main-title-container">
                    <h1 className='Honey-products-heading'>Honey Products</h1>
                </div>
                <div className='products-container'>
                    {honeyProducts.map((product, index) => (
                        <Link to={product.path} className='clear_underline'><div key={index} className='product-card'>
                            <div className="card" style={{ width: '20rem', height: '20rem' }}>
                                <div className="container">
                                    <div>
                                        <img src={product.imgSrc} className="card-img-top image" alt={product.name} />
                                    </div>
                                    <div className="overlay">
                                        <div className="text">{product.name}</div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <h5 className='Honey-heading'>{product.name}</h5>
                                    <p className="card-text"><span className='Available'>Available: </span> {product.availableSizes}</p>
                                    <div className='buttons-container'>
                                        <p className='pricetext'>{product.price}</p>
                                        <Link to={product.path}>
                                            <button className='btn btn-success btn_buybutton'>Buy Now</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div></Link>
                    ))}
                </div>
            </div>
        </motion.div>
    );
};

export default HoneyProducts;
