import React, { useState } from 'react';
import axios from 'axios';
import './index.css';

const Useraccout = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [message, setMessage] = useState('');

  const handleSendOtp = async () => {
    try {
      await axios.post('http://localhost:5001/send-otp', { mobileNumber });
      setOtpSent(true);
      setMessage('OTP sent successfully!');
    } catch (error) {
      console.error('Error sending OTP:', error);
      setMessage('Error sending OTP');
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post('http://localhost:5001/verify-otp', { mobileNumber, otp });
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setMessage('Error verifying OTP');
    }
  };

  return (
    <div className='userlogin-container'>
      <div className="user-container">
        <h1>Login / Sign up</h1>
        {!otpSent ? (
          <div>
            <input
              type="text"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              placeholder="Enter Mobile Number"
              className="user-input"
            />
            <button onClick={handleSendOtp} className="user-button">Get OTP</button>
          </div>
        ) : (
          <div>
            <input
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              className="user-input"
            />
            <button onClick={handleVerifyOtp} className="user-button">Verify OTP</button>
          </div>
        )}
        {message && <div className="user-message">{message}</div>}
      </div>
    </div>
  );
}

export default Useraccout;
