import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { faInstagram,faWhatsapp,faLinkedin} from '@fortawesome/free-brands-svg-icons';
import {  Link } from "react-router-dom";
import './index.css'

const handleWhatsappRedirect = () => {
    const whatsappNumber = '9962754292'; // Replace with your WhatsApp number
    const message = encodeURIComponent('Hello! I have a question.');
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${message}`;
    window.location.href = whatsappUrl;
    // window.open(whatsappUrl, '_blank');
};

const Footer = () =>{
    return(
        <>
        <div class="bg-container">
            <div class="Footer-container">
                <div  className="text-center SocialMedia-Follow-container">
                    <div className='company-container2'>
                        <div className='company-container'>
                        <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1712129337/1_ozrowa.png" className="logofooter" />
                        <p className='footer-comapnay-name'>B Organics</p>
                        </div>
                    <div className="Social-icons-container">
                        <div className="media media-faLinkedin">
                            <a href="https://www.linkedin.com/company/borganics/" target="_blank" rel="noopener noreferrer" className='socialMedia'>
                            <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                        </div>                        
                        <div className="media media-faInstagram">
                            <a href="https://www.instagram.com/borganics.in/" target="_blank" rel="noopener noreferrer" className='socialMedia'>
                            <FontAwesomeIcon icon={faInstagram} />
                            </a>
                        </div>
                        <div className="media media-faWhatsapp" onClick={handleWhatsappRedirect}>
                            <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon"/>
                        </div>
                        <div className="media media-faEnvelope">
                            <a href="mailto:info@borganics.in" target="_blank" rel="noopener noreferrer" className='socialMedia'>
                            <FontAwesomeIcon icon={faEnvelope} />
                            </a>
                        </div>
                        </div>
                    </div>                   
                </div>

                {/* <div class="col-6 col-md-6 col-lg-3 pt-5 products-container">
                    <Link to='/About'><h1 class="para pt-3">About</h1></Link>
                    <Link to='/Menu' ><p className="para footer-section-mail-id">Explore Menu</p></Link>
                    <Link to='/whychooseus' ><p className="para">Why Choose Us?</p></Link>                    
                </div> */}
                   
                        <div className='promise_Infor-conatiner'>
                            <div class="products-containe">
                                <Link to='/aboutus' className="para"><h1 className="para pt-3">About US</h1></Link>
                                <Link to='/HoneyProducts' className="para" ><p className="para footer-section-mail-id">Menu</p></Link>
                                <Link to='/contactus' className="para" ><p>Contact US</p></Link>
                                <Link to="/admin" className="para"><p>Admin Login</p></Link>     
                            </div>                       
                            <div class="products-containe">
                                <h1 className="footer-comapnay-name pb-3 pt-3">Location</h1>
                                <h1 className="headingfooter">Head Office : Chennai</h1>
                                <p className='headingfooter'>www.borganics.in</p>
                                <p className="para">info@borganics.in</p>
                            </div> 
                        </div>
                        <div className='promise_Infor-conatiner'>
                            <div className="order-2 products-containe">
                                <h1 className="heading2 pb-3 pt-3">We promise you</h1>
                                <Link to="/qualityproduct" className="para"><p className="para">100% Quality Product</p></Link>
                                <Link to="/protectyourdetails" className="para"><p>Protect Your Details</p></Link>                            
                            </div>                        
                            <div className="order-2 products-containe">
                                <h1 className="heading2 pb-3 pt-3">Information</h1>
                                <Link to="/termsandconditions" className="para"><p className="para">Terms and conditions</p></Link>
                                <Link to="/privacypolicy" className="para"><p className="para">Privacy policy</p></Link>
                                <Link to="/cancellationpolicy" className="para"><p className="para">Cancellation policy</p></Link>
                                {/* <Link to="/fssailicense" className="para"><p className="para">FSSAI licence</p></Link> */}
                                <Link to="/shippingpolicy" className="para"><p>Shipping policy</p></Link>
                            </div>
                        </div>                    
                
        </div>
        <hr className="hr-line" />
        <div className="para text-center pb-5">
            <i className="far fa-copyright">Copyrights received © 2024 Borganics.</i>
        </div>
    </div>
    </>
    )
}
export default Footer;