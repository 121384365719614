import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import './index.css'; // Import CSS file

const CustomerOrders = ({ isLoggedIn, onLogout }) => {
  const [orders, setOrders] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  // Function to fetch orders
  const fetchOrders = async () => {
    try {
      const response = await axios.get('https://borganics-backend-code.onrender.com/api/orders');
      setOrders(response.data.orders.reverse());
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    }
  };

  useEffect(() => {
    // Fetch orders when logged in
    if (isLoggedIn) {
      fetchOrders();
    }
  }, [isLoggedIn]);

  // Filter orders based on search input
  const filteredOrders = orders.filter(order =>
    (order.orderId && order.orderId.includes(searchInput)) ||
    (order.username && order.username.includes(searchInput)) ||
    (order.product && order.product.includes(searchInput)) ||
    (order.price && order.price.toString().includes(searchInput)) ||
    (order.quantity && order.quantity.toString().includes(searchInput)) ||
    (order.email && order.email.includes(searchInput)) ||
    (order.paymentMethod && order.paymentMethod.includes(searchInput)) ||
    (order.addr && order.addr.includes(searchInput)) ||
    (order.phone && order.phone.includes(searchInput)) ||
    (order.time && order.time.includes(searchInput))
  );

  return (
    <div className='customerdetails-container'>
      <h1 className="AdminDashboard_h3">Order Details</h1>
      <input
        type="text"
        placeholder="Search..."
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        className='searchinput'
      />
      <div className="logout-icon-container" onClick={onLogout}>
        <FontAwesomeIcon icon={faRightFromBracket} size="1x" className="logout-icon" />
        <h5>Logout</h5>
      </div>
      <div className='list-of-orders'>
        <table className="AdminDashboard_table">
          <thead>
            <tr>
              <th>Order #</th>
              <th>OrderId</th>
              <th>Username</th>
              <th>Product</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Email</th>
              <th>Payment Method</th>
              <th>Address</th>
              <th>Phone</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order, index) => (
              <tr key={index}>
                <td>{filteredOrders.length - index}</td>
                <td>{order.orderId}</td>
                <td>{order.username}</td>
                <td>{order.product}</td>
                <td>{order.price}</td>
                <td>{order.quantity}</td>
                <td>{order.email}</td>
                <td>{order.paymentMethod}</td>
                <td>{order.addr}, {order.pin}</td>
                <td>{order.phone}</td>
                <td>{order.time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerOrders;
