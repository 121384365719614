import React, { useEffect, useState } from 'react';
import Footer from '../../Footer';
import { useNavigate } from 'react-router-dom';
import RecommendProduct from '../../RecommendProduct';
import { motion } from 'framer-motion';
import './index.css';

const RobustaCoffee50 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/2_euz06q.jpg"
const RobustaCoffee100 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/2_euz06q.jpg"
const RobustaCoffee250 = "https://res.cloudinary.com/drevfgyks/image/upload/v1716655305/B%20organics/2_euz06q.jpg"

const HoneyBuypage = () => {
  useEffect(() => {
    const cursol = document.querySelector(".cursol");
    const cursol2 = document.querySelector(".cursol2");

    const handleMouseMove = (e) => {
        cursol.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
        cursol2.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
    };

    const handleMouseEnter = () => {
        cursol.classList.add("hover-effect");
    };

    const handleMouseLeave = () => {
        cursol.classList.remove("hover-effect");
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
        element.addEventListener("mouseenter", handleMouseEnter);
        element.addEventListener("mouseleave", handleMouseLeave);
    });

    return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
            element.removeEventListener("mouseenter", handleMouseEnter);
            element.removeEventListener("mouseleave", handleMouseLeave);
        });
    };
}, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [selectedSize, setSelectedSize] = useState("250g");
  const [selectedProduct, setSelectedProduct] = useState({
    name: 'Arabic AAA Honey',
    size: '250g',
    price: 'Rs 655.00',
  });

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };
  
  useEffect(() => {
    if (selectedSize) {
      setSelectedProduct({
        name: 'Arabic AAA Honey',
        size: selectedSize,
        price: getPrice(selectedSize),
      });
    }
  }, [selectedSize]);

  const getPrice = (size) => {
    switch (size) {
      case '50g':
        return 'Rs 155.00';
      case '100g':
        return 'Rs 270.00';
      case '250g':
        return 'Rs 655.00';
      default:
        return '';
    }
  };

  const handleBuyNow = () => {
    const selectedProduct = {
      name: 'Arabic AAA',
      size: selectedSize,
      price: getPrice(selectedSize),
    };
    navigate('/Robustacoffee/checkoutpage', { state: { selectedProduct } }); // Navigate to checkout page with state
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 5, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.9 }}
      >
        <div className='Coffee-product-container-Robu'>
          <div className='container mt-5'>
            <div className='row'>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex  justify-content-center">
                <div className="product-bottle">
                  <div className="product-card-container">
                    <div>
                      {/* Product Image and Preview Images */}
                      {selectedSize === "50g" && (
                        <img src={RobustaCoffee50}
                        className='Honey-product' alt="Honey product" />
                      )}
                      {selectedSize === "100g" && (
                        <img src={RobustaCoffee100} className='Honey-product' alt="Honey product" />
                      )}
                      {selectedSize === "250g" && (
                        <img src={RobustaCoffee250} className='Honey-product' alt="Honey product" />
                      )}
                    </div>
                  </div>
                  {/* Other product preview images */}
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5 justify-content-center'>
                <h1 className=" colorselft name-for-product mt-5">Robusta AAA</h1>
                <div>
                      {/* Product Image and Preview Images */}
                      {selectedSize === "50g" && (
                        <p className='colorselft'>size : 50g<br/>Price : Rs 155.00</p>             
                      )}
                      {selectedSize === "100g" && (
                        <p className='colorselft'>size : 100g<br/>Price : Rs 270.00</p> 
                      )}
                      {selectedSize === "250g" && (
                        <p className='colorselft'>size : 250g<br/>Price : Rs 655.00</p> 
                      )}
                    </div>
                {/* Product description */}
                <div className='colorselft prodcut-size-card'>
                <label htmlFor="size">Select Size:</label>
                  <select id="size" className='select-container select-container2' value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)}>
                    <option value="50g">50 gm</option>
                    <option value="100g">100 gm</option>
                    <option value="250g">250 gm</option>
                  </select>
              </div>
                <div className='button_container'>
                  <button className='button_BuyNow' onClick={handleBuyNow}>Buy Now</button>
                </div>
                {/* Other product details */}
              </div>
            </div>
          </div>
          <hr className='hrClass'/>
          <h1 className="colorselft menu-section-heading mb-2 mt-5">Recommendation</h1>
          <div className="explore-menu-section2" id="exploreMenuSection">
              <div className="cardcenter">   
                {/* Pass selectedSize as prop to RecommendProduct */}
                <RecommendProduct
                  imageSrc={RobustaCoffee50}
                  name="Arabic AAA-50g"
                  price="Rs 155.00"
                  size="50g"
                />
                <RecommendProduct
                  imageSrc={RobustaCoffee100}
                  name="Arabic AAA-100g"
                  price="Rs 270.00"
                  size="100g"
                />
                <RecommendProduct
                  imageSrc={RobustaCoffee250}
                  name="Arabic AAA-250g"
                  price="Rs 655.00"
                  size="250g"
                />
              </div>
          </div>
          <Footer />
        </div>
      </motion.div>
      <div className="cursol"></div>
      <div className="cursol2"></div>
    </>
  );
};

export default HoneyBuypage;
