import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import Footer from '../Footer';
import 'animate.css';
import './index.css';

const HoneyProducts = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const honeyProducts = [
        {
            name: 'Wild Forest Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/3_hxrhgi.jpg',
            path: '/wildforesthoney',
            price: 'Rs 410.00',
            availableSizes: '50gm, 100gm, 250gm'
        },
        {
            name: 'Acacia Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/4_g803yw.jpg',
            path: '/acaciahoney',
            price: 'Rs 410.00',
            availableSizes: '50gm, 100gm, 250gm'
        },
        {
            name: 'Moringa Honey',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/2_tbtdul.jpg',
            path: '/moringahoney',
            price: 'Rs 410.00',
            availableSizes: '50gm, 100gm, 250gm'
        }
    ];

    return (
        <>
            <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.9 }}
                className="honey-products"
            >
                <div className='HoneyProduct2-container mt-3'>
                    <div className="Honey-main-title-container2">
                        <h1 className='Honey-products-heading2'>Honey Products</h1>
                    </div>
                    <div className='products-container2'>
                        {honeyProducts.map((product, index) => (
                            <div key={index} className='mb-5 mt-1'>
                                <div className="card" style={{ width: '15rem', height: '15rem' }}>
                                    <div className="container">
                                        <Link to={product.path}>
                                            <img src={product.imgSrc} className="card-img-top image" alt={product.name} />
                                        </Link>
                                        <div className="overlay">
                                            <div className="text">{product.name}</div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <h5 className='Honey-heading'>{product.name}</h5>
                                        <p className="card-text"><span className='Available'>Available: </span> {product.availableSizes}</p>
                                        <div className='buttons-container'>
                                            <p className='pricetext'>{product.price}</p>
                                            <Link to={product.path}>
                                                <button className='btn btn-success btn_buybutton'>Buy Now</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='emptyBOX-container'>
                        <div className='emptyBOX animate__animated animate__swing' style={{ width: '20rem', height: '12rem' }}>
                            <ul className='emptyBOX-para-Honey'>
                                <li>Good source of antioxidants</li>
                                <li>Raw honey nutrition</li>
                                <li>Anti-bacterial and anti-fungal properties</li>
                                <li>Healing wounds</li>
                                <li>Phytonutrients powerhouse</li>
                                <li>Help for digestive issues</li>
                                <li>Soothe a sore throat and cough</li>
                                <li>Brain benefits</li>
                            </ul>
                        </div>
                        <div className='emptyBOX emptyBOXself animate__animated animate__swing' style={{ width: '20rem', height: '12rem' }}>
                            <ul className='emptyBOX-para-Honey'>
                                <li>Healing wounds</li>
                                <li>Suitable for people - sensitive sugar</li>
                                <li>Reduces body weight</li>
                                <li>Healing wounds</li>
                                <li>Good for gut ecology </li>
                                <li>Skin care</li>
                                <li>Helpful to hypoglycemia</li>
                                <li>Anging, Cancer & variety of disease</li>
                            </ul>
                        </div>
                        <div className='emptyBOX emptyBOXself animate__animated animate__swing' style={{ width: '20rem', height: '12rem' }}>
                            <ul className='emptyBOX-para-Honey'>
                                <li>Reduces pigmentation & controls blemishes</li>
                                <li>Anti-Bacterial, anti-fungal, anti-microbial to combat infections</li>
                                <li>Detoxify the gut</li>
                                <li>Arthritis pain</li>
                                <li>Surpasses cancer cells</li>
                                <li>Throat infections</li>
                                <li>Reduces ulcers</li>
                                <li>Excellent cough & throat infection medicine</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Footer/>
            </motion.div>
        </>
    );
};

export default HoneyProducts;
