import React, { useEffect, useState } from 'react';
import Footer from '../../Footer';
import { useNavigate } from 'react-router-dom';
import RecommendProduct from '../../RecommendProduct';
import { motion } from 'framer-motion';
import './index.css';

const HoneyBuypage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [selectedSize, setSelectedSize] = useState('250g');

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleBuyNow = () => {
    const selectedProduct = {
      name: 'Wild Forest Honey',
      size: selectedSize,
      price: getPrice(selectedSize),
    };
    navigate('/checkoutpage', { state: { selectedProduct } });
  };

  useEffect(() => {
    const cursol = document.querySelector(".cursol");
    const cursol2 = document.querySelector(".cursol2");

    const handleMouseMove = (e) => {
        cursol.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
        cursol2.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
    };

    const handleMouseEnter = () => {
        cursol.classList.add("hover-effect");
    };

    const handleMouseLeave = () => {
        cursol.classList.remove("hover-effect");
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
        element.addEventListener("mouseenter", handleMouseEnter);
        element.addEventListener("mouseleave", handleMouseLeave);
    });

    return () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
            element.removeEventListener("mouseenter", handleMouseEnter);
            element.removeEventListener("mouseleave", handleMouseLeave);
        });
    };
}, []);


  return (
    <>
      <motion.div
        initial={{ opacity: 5, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.9 }}
      >
        <div className='Honey-product-container'>
          <div className='container mt-5 top-section'>
            <div className='row'>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex  justify-content-center">
                <div className="product-bottle ">
                  <div className="product-card-container">
                    <img
                      src={getImageUrl(selectedSize)}
                      className='Honey-product' alt="Honey product"
                    />
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-5 justify-content-center'>
                <h1 className="name-for-product onlycolor mt-5">Wild Forest Honey</h1>
                <div>
                  <p className='onlycolor1'>Size: {selectedSize}</p>
                  <p className='onlycolor1'>Price: {getPrice(selectedSize)}</p>
                </div>
                <div className='prodcut-size-card'>
                  <p className='onlycolor1'>Select Size</p>
                  <select className='select-container' value={selectedSize} onChange={handleSizeChange}>
                    <option className='option' value="50g">50 gm</option>
                    <option className='option' value="100g">100 gm</option>
                    <option className='option' value="250g">250 gm</option>
                  </select>
                </div>
                <div className='button_container'>
                  <button className='button_BuyNow8' onClick={handleBuyNow}>Buy Now</button>
                </div>
              </div>
            </div>
          </div>
          <hr className='hrClass'/>
          <h1 className="menu-section-heading onlycolor mb-2 mt-5">Recommendation</h1>
          <div className="explore-menu-section2" id="exploreMenuSection">
            <div className="cardcenter">              
              <RecommendProduct
                imageSrc="https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/3_hxrhgi.jpg"
                name="Wild Forest Honey-50g"
                price="Rs 75.00"
                size="50g"
              />
              <RecommendProduct
                imageSrc="https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/3_hxrhgi.jpg"
                name="Wild Forest Honey-100g"
                price="Rs 155.00"
                size="100g"
              />
              <RecommendProduct
                imageSrc="https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/3_hxrhgi.jpg"
                name="Wild Forest Honey-250g"
                price="Rs 410.00"
                size="250g"
              />
            </div>
          </div>
          <Footer />
        </div>
      </motion.div>
      <div className="cursol"></div>
      <div className="cursol2"></div>
    </>
  );
};

export default HoneyBuypage;

const getImageUrl = (size) => {
  switch (size) {
    case '50g':
      return "https://res.cloudinary.com/drevfgyks/image/upload/v1715361213/B%20organics/WildforestHoney_50gm_klva4m.jpg";
    case '100g':
      return "https://res.cloudinary.com/drevfgyks/image/upload/v1715361213/B%20organics/WildforestHoney_100gm_u6lcng.jpg";
    case '250g':
      return "https://res.cloudinary.com/drevfgyks/image/upload/v1716650812/B%20organics/3_hxrhgi.jpg";
    default:
      return '';
  }
};

const getPrice = (size) => {
  switch (size) {
    case '50g':
      return 'Rs 75.00';
    case '100g':
      return 'Rs 155.00';
    case '250g':
      return 'Rs 410.00';
    default:
      return '';
  }
};
