import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Footer from '../Footer';
import './index.css';

const CoffeeProduct = () => {
    useEffect(() => {
        const preventRightClick = (e) => {
            e.preventDefault();
        };
        window.addEventListener('contextmenu', preventRightClick);
        return () => {
            window.removeEventListener('contextmenu', preventRightClick);
        };
    }, []);

    const coffeeProducts = [
        {
            name: 'Arabic AAA',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716653387/B%20organics/www.borganics.in_1_a4mk5m.jpg',
            path: '/Arabicacoffee',
            price: 'Rs 690.00',
            availableSizes: '50gm, 100gm, 250gm'
        },
        {
            name: 'Robusta AAA',
            imgSrc: 'https://res.cloudinary.com/drevfgyks/image/upload/v1716653387/B%20organics/www.borganics.in_1_a4mk5m.jpg',
            path: '/RobustaCoffee',
            price: 'Rs 655.00',
            availableSizes: '50gm, 100gm, 250gm'
        }
    ];

    return (
        <>
            <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.9 }}
            >
                <div className='CoffeeProduct2-container mt-3'>
                    <div>
                        <div className="coffee-main-title-container2">
                            <h1 className='coffee-products-heading2'>Coffee Products</h1>
                        </div>
                        <div className='coffee-products-container2'>
                            {coffeeProducts.map((product, index) => (
                                <div key={index} className='mb-5 mt-1'>
                                    <div className="card" style={{ width: '15rem', height: '15rem' }}>
                                        <div className="container">
                                            <Link to={product.path}>
                                                <img src={product.imgSrc} className="card-img-top image2" alt={product.name} />
                                            </Link>
                                            <div className="overlay">
                                                <div className="text">{product.name}</div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <h5 className='Coffee-Heading'>{product.name}</h5>
                                            <p className="card-text"><span className='Available'>Available: </span> {product.availableSizes}</p>
                                            <div className='buttons-container'>
                                                <p className='pricetext'>{product.price}</p>
                                                <Link to={product.path}>
                                                    <button className='btn btn-success btn_buybutton'>Buy Now</button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='coffee-emptyBOX-container'>
                        <div className='coffee-emptyBOX animate__animated animate__swing' style={{ width: '20rem', height: '12rem' }}>
                            <ul className='emptyBOX-para2'>
                                <li>Rich Flavor Profile</li>
                                <li>Higher Antioxidant Content</li>
                                <li>Lower Caffeine Content</li>
                                <li>Improved Cognitive Function</li>
                                <li>Supports Heart Health</li>
                                <li>Aids in Weight Management</li>
                            </ul>
                        </div>
                        <div className='coffee-emptyBOX emptyBOXself animate__animated animate__swing' style={{ width: '20rem', height: '12rem' }}>
                            <ul className='emptyBOX-para2'>
                                <li>Higher Caffeine Content</li>
                                <li>Richer in Antioxidants</li>
                                <li>Distinctive Flavor Profile</li>
                                <li>Versatility in Blends</li>
                                <li>Improved Physical Performance</li>
                                <li>Neurological Benefits</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Footer/>
            </motion.div>
        </>
    );
};

export default CoffeeProduct;
