import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faBowlFood, faEarthEurope, faUserShield } from '@fortawesome/free-solid-svg-icons';
import HoneyProducts from '../HoneyProducts';
import CoffeeProduct from '../CoffeeProducts';
import UserFeedback from '../Userfeedback';
import Footer from '../Footer';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'; // Import the custom CSS file

import Borganicsbag from './LandingpageImages/Borganics-bag.png';

const Landingpage = ({ history }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images = [
        "https://res.cloudinary.com/drevfgyks/image/upload/v1715930274/B%20organics/20240517_124525_sh4swk.jpg",
        "https://res.cloudinary.com/drevfgyks/image/upload/v1715932001/B%20organics/20240517_130129_yyuor6.jpg",
        "https://res.cloudinary.com/drevfgyks/image/upload/v1716302193/B%20organics/view-fly-insect-with-wings_fas9tg.png"
    ];

    useEffect(() => {
        window.scrollTo(0, 0);

        const intervalId = setInterval(() => {
            const nextIndex = Math.floor(Math.random() * images.length);
            setCurrentImageIndex(nextIndex);
        }, 5000);

        return () => clearInterval(intervalId);
    }, [images.length]);

    useEffect(() => {
        const cursol = document.querySelector(".cursol");
        const cursol2 = document.querySelector(".cursol2");

        const handleMouseMove = (e) => {
            cursol.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
            cursol2.style.cssText = `left: ${e.clientX}px; top: ${e.clientY}px;`;
        };

        const handleMouseEnter = () => {
            cursol.classList.add("hover-effect");
        };

        const handleMouseLeave = () => {
            cursol.classList.remove("hover-effect");
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
            element.addEventListener("mouseenter", handleMouseEnter);
            element.addEventListener("mouseleave", handleMouseLeave);
        });

        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.querySelectorAll('.app-container, .some-other-class').forEach(element => {
                element.removeEventListener("mouseenter", handleMouseEnter);
                element.removeEventListener("mouseleave", handleMouseLeave);
            });
        };
    }, []);

    return (
        <>
            <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
                className="honey-products"
            >
                <div className='app smail img-fluid' style={{ backgroundImage: `url(${images[currentImageIndex]})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%" }}></div>
                <div className='app-container'>
                    <div>
                        <div className='Products-container'>
                            <div className='HoneyAndCoffee_Components'>
                                <HoneyProducts />
                                <CoffeeProduct />
                            </div>                            
                            <div className='container'>
                                <div className='row'>
                                    {/* Add any additional content here */}
                                </div>
                            </div>
                            <div className="Landingpage-whoweare2 ">
                                <div className='Landingpage-whoweare-top'>
                                    <div className='image-rule'>
                                        <img src="https://res.cloudinary.com/drevfgyks/image/upload/v1715944432/B%20organics/100-natural-nutrion-healthy-eating-life-2_w4weq5.jpg"
                                            className='organicsimage'
                                            alt='ProductProccess' />
                                    </div>
                                </div>
                                <div className='Landingpage-whoweare'>
                                    <div className='icons-container animate__animated animate__fadeInLeft'>
                                        <div className='whoweare-icons whoweare-icons-self '>
                                            <FontAwesomeIcon icon={faLeaf} className='who-icons' />
                                            <p className='whowe-are-para'>Our farm-fresh produce is cultivated with care, free from pesticides & harmful chemicals.</p>
                                        </div>
                                        <div className='whoweare-icons '>
                                            <FontAwesomeIcon icon={faBowlFood} className='who-icons' />
                                            <p className='whowe-are-para'>Our selection offers a delicious blend of taste and nutrition, Indulge in our flavorful options.</p>
                                        </div>
                                    </div>
                                    <div className='icons-container animate__animated animate__fadeInLeft'>
                                        <div className='whoweare-icons '>
                                            <FontAwesomeIcon icon={faEarthEurope} className='who-icons' />
                                            <p className='whowe-are-para'>Our farming methods are scientific and steered towards and biodiversity conservation</p>
                                        </div>
                                        <div className='whoweare-icons '>
                                            <FontAwesomeIcon icon={faUserShield} className='who-icons' />
                                            <p className='whowe-are-para'>Our products from our farmers are healthy and biologically safe for consumption..</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='Landingpage-whoweare-top-2 row col-12'>
                                    <div className='whyusheadeing2 '>
                                        <div className='col-2 product-details'>
                                            <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1713450311/B%20organics/Fresh-PNG-Free-Image_gua0lm.png'
                                                className='Freshself'
                                                alt='img-landing' />
                                            <h5 className='landing-cycle-heading'>100% Fresh Products</h5>
                                            <p className='whyus-para'>our selection is carefully cultivated using sustainable farming practices.
                                                Elevate your senses with our collection of 100% fresh products.</p>
                                        </div>
                                        <div className='col-2 product-details'>
                                            <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1713450311/B%20organics/100-Natural-PNG-Photo_mmn2tr.png'
                                                className='whyusimage whyusimageself'
                                                alt='img-landing' />
                                            <h5 className='landing-cycle-heading landing-cycle-headingself'>Natural Products</h5>
                                            <p className='whyus-para'>Discover the purity of nature with our 100% natural organic products.
                                                Handpicked and crafted with care, each item is a testament to the beauty of Mother Earth.</p></div>
                                    </div>
                                    <div className='whyus-image-comtainer'>
                                        <div className='product-details col-2'>
                                            <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1713450311/B%20organics/healthy-eating_wgpk0n.png'
                                                className='whyusimage'
                                                alt='img-landing' />
                                            <h5 className='landing-cycle-heading'>Healthy Eat</h5>
                                            <p className='whyus-para'>We think nature is really powerful for making us feel healthy and happy. That's why we 
                                            carefully pick and make each thing in our collection, always thinking about how it can help you stay healthy.</p>
                                        </div>
                                        <div className='product-details'>
                                            <img src={Borganicsbag}
                                                className='whyusimage-bag'
                                                alt='img-landing' />
                                        </div>
                                        <div className='product-details col-2'>
                                            <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1713450311/B%20organics/qnfs1_y1xq6x.png'
                                                className='whyusimage'
                                                alt='img-landing' />
                                            <h5 className='landing-cycle-heading'>Product Quality</h5>
                                            <p className='whyus-para'>We spare no effort in ensuring that every product maintains its pristine quality and integrity. With our steady commitment to quality assurance, you can trust that every purchase embodies the pinnacle of excellence.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='userfeedback_container'>
                                <UserFeedback />
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </motion.div>
            <div className="cursol"></div>
            <div className="cursol2"></div>
        </>
    );
};

export default Landingpage;
