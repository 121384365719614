import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import './CheckoutPage.css';
import { motion } from 'framer-motion';

// Initialize the order counter for Honey and Coffee
let honeyOrderCount = 0;
let coffeeOrderCount = 0;

// Function to generate order ID
const generateOrderId = (orderType) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adding leading zero if month < 10

  let orderId;
  let orderNumber;

  if (orderType === 'Honey') {
      honeyOrderCount++;
      orderNumber = honeyOrderCount.toString().padStart(5, '0');
      orderId = `${year}${month}H${orderNumber}`;
  } else if (orderType === 'Coffee') {
      coffeeOrderCount++;
      orderNumber = coffeeOrderCount.toString().padStart(5, '0');
      orderId = `${year}${month}C${orderNumber}`;
  } else {
      throw new Error('Invalid order type');
  }

  return orderId;
};


const CheckoutPage = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const selectedProduct = location.state ? location.state.selectedProduct : {};

  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    address: '',
    pin: '',
    paymentMethod: ''
  });

  const [formErrors, setFormErrors] = useState({
    fullname: '',
    email: '',
    address: '',
    pin: '',
    phone: '',
    paymentMethod: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      try {

        // Generate the order ID based on the selected product type
        const orderType = selectedProduct.type === 'Honey' ? 'Honey' : 'Coffee';
        const orderId = generateOrderId(orderType);

        const data = new FormData()
        data.append("price", selectedProduct.price)
        data.append("product", selectedProduct.name)
        data.append("quantity", selectedProduct.size)
        data.append("email", formData.email)
        data.append("paymentMethod", formData.paymentMethod)
        data.append("addr", formData.address)
        data.append("pin", formData.pin)
        data.append("phone", formData.phone)
        data.append("username", formData.fullname)
        data.append("orderId", orderId); // Add the orderId to the form data




        const response = await fetch('https://borganics-backend-code.onrender.com/api/place/order', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            price: selectedProduct.price,
            product: selectedProduct.name,
            quantity: selectedProduct.size,
            email: formData.email,
            paymentMethod: formData.paymentMethod,
            addr: formData.address,
            pin: formData.pin,
            phone: formData.phone,
            username: formData.fullname,
            orderid: orderId // Add the orderId to the JSON body
          }),
        });
        if (response.ok) {
          const msg = await response.json()
          console.log(msg)
          if (msg.msg === "orderCreated..") {
            console.log('Form submitted successfully');
            navigate('/Confimedorder');

          }

        } else {
          console.log('Form submission failed:', await response.json());
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('An error occurred. Please try again.');
      }
    } else {
      console.log('Form submission failed. Please fill in all required fields.');
      alert('Please fill in all required fields before placing the order.');
    }
  };


  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Validate firstName
    if (!formData.fullname.trim()) {
      errors.fullname = 'Please enter your first name';
      isValid = false;
    }


    // Validate email
    if (!formData.email.trim()) {
      errors.email = 'Please enter your email';
      isValid = false;
    }

    // Validate address
    if (!formData.address.trim()) {
      errors.address = 'Please enter your address';
      isValid = false;
    }

    // Validate zip
    if (!formData.pin.trim()) {
      errors.pin = 'Please enter your ZIP code';
      isValid = false;
    }

    // Validate phone
    if (!formData.phone.trim()) {
      errors.phone = 'Please enter your phone number';
      isValid = false;
    }

    // Validate paymentMethod
    if (!formData.paymentMethod.trim()) {
      errors.paymentMethod = 'Please select a payment method';
      isValid = false;
    }

    console.log('isValid:', isValid); // Add this line for debugging
    console.log('errors:', errors); // Add this line for debugging

    setFormErrors(errors);

    return isValid;
  };

  return (
    <>
    <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9 }}
        >
      
      <div className="CheckoutPage-checkout-container mt-5">       
        <div className="CheckoutPage-checkout-form-container">
        <h1 className="CheckoutPage-checkout-heading">Checkout</h1>
        <div className="selected-product-details">
          <h1 className='Selected-heading mb-5'>Selected Product Details:</h1>
          <p>Name: {selectedProduct.name}</p>
          <p>Size: {selectedProduct.size}</p>
          <p>Price: {selectedProduct.price}</p>
        </div>          
          <form onSubmit={handleSubmit} className="checkoutpage-form">
            <div className="form-group checkoutpage-form-group">
              <label className="checkoutpage-label">Full Name</label>
              <input type="text" className="form-control checkoutpage-input" name="fullname" value={formData.fullname} onChange={handleChange} required />
              {formErrors.fullname && <span className="checkoutpage-error">{formErrors.fullname}</span>}
            </div>
            <div className="form-group checkoutpage-form-group">
              <label className="checkoutpage-label">Email</label>
              <input type="email" className="form-control checkoutpage-input" name="email" value={formData.email} onChange={handleChange} required />
              {formErrors.email && <span className="checkoutpage-error">{formErrors.email}</span>}
            </div>
            <div className="form-group checkoutpage-form-group">
              <label className="checkoutpage-label">Phone No</label>
              <input type="tel" className="form-control checkoutpage-input" name="phone" value={formData.phone} onChange={handleChange} required />
              {formErrors.phone && <span className="checkoutpage-error">{formErrors.phone}</span>}
            </div>
            <div className="form-group checkoutpage-form-group">
              <label className="checkoutpage-label">Address</label>
              <input type="text" className="form-control checkoutpage-input" name="address" value={formData.address} onChange={handleChange} required />
              {formErrors.address && <span className="checkoutpage-error">{formErrors.address}</span>}
            </div>
            <div className="form-group checkoutpage-form-group">
              <label className="checkoutpage-label">PIN Code</label>
              <input type="text" className="form-control checkoutpage-input" name="pin" value={formData.pin} onChange={handleChange} required />
              {formErrors.pin && <span className="checkoutpage-error">{formErrors.pin}</span>}
            </div>
            <div className="form-group checkoutpage-form-group">
              <label className="checkoutpage-label">Payment Method</label>
              <select className="form-control checkoutpage-input" name="paymentMethod" value={formData.paymentMethod} onChange={handleChange} required>
                <option value="">Select Payment Method</option>
                {/* <option value="credit_card">Credit Card</option>
                <option value="paypal">PayPal</option>
                <option value="upi">UPI</option> */}
                <option value="cash_on_delivery">Cash on Delivery</option>
              </select>
              {formErrors.paymentMethod && <span className="checkoutpage-error">{formErrors.paymentMethod}</span>}
            </div>
            <button type="submit" className="btn btn-primary checkoutpage-btn">Place Order</button>
          </form>
        </div> 
        <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1715127644/Girl_image_tatgj5.png'
        className='Girl-image'
        alt='girlimage'/>
         <img src='https://res.cloudinary.com/drevfgyks/image/upload/v1715130593/B%20organics/check-out_wttgfw.jpg'
        className='Checkout'
        alt='girlimage'/>
      </div>  
      <Link to="/honeybuypage" className="btn btn-secondary m-3">Go Back</Link>
      <Footer />
      </motion.div>
    </>    
  );
};

export default CheckoutPage;
